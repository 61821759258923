import { useEffect, useState } from "react"

export const useActiveBrowserTab = () => {
    const [active, setActive] = useState<boolean>(true)
    useEffect(() => {
        const handleFocus = () => {
            setActive(true)
        }

        const handleBlur = () => {
            setActive(false)
        }

        window.addEventListener('focus', handleFocus)
        window.addEventListener('blur', handleBlur)

        return () => {
            window.removeEventListener('focus', handleFocus)
            window.removeEventListener('blur', handleBlur)
        }

    }, [])

    return active
}