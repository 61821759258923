import React from 'react'
import CurrentStackChange from "./CurrentStackChange"

interface HeaderExtrasProps {
}

const HeaderExtras: React.FunctionComponent<HeaderExtrasProps> = () => {

    return (
        <div className="d-flex align-items-center justify-content-end flex-1 mr-1">
            <CurrentStackChange />
        </div>
    )
}

export default HeaderExtras
