import { gql } from "@apollo/client";
import { Prettify } from "@types";

export type StackLazyType = {
  Store: {
    Value?: {
        Description: string;
        StoreNumber: string;
    }
  };
  OwnedByUser: {
      Value?: {
          Firstname: string;
          Surname: string;
      }
  };
  StackTickets: {
    ID: number;
    Quantity: number;
    TicketID: number;
    Ticket: {
      Value?: {
        TicketReference: string;
        Size: {
          Value?: {
            Name: string;
          }
        }
      }
    }
  }[]
}

export type StackType = Prettify<{
    ID: number;
    Description: string;
    Message: string;
    PrivateStack: boolean;
    Active: boolean;
    TotalTickets: number;
    CreatedByUserID: number;
    LastUpdatedByUserID: number;
    LastUpdatedDateTime: string;
    OrganisationID: number;
    OwnedByUserID: number;
    StoreID: number;
} & StackLazyType>

export const StacksGridQuery = gql`
  query StackGrid(
    $skip: Int,
    $take: Int,
    $order: [StackSortInput!],
    $where: StackFilterInput,
    $excludeNonStore: Boolean
  ) {
    grid: Stacks(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where,
      ExcludeNonStore: $excludeNonStore
    ) {
      totalCount
      items {
        ID
        Description
        Active
        Message
        PrivateStack
        TotalTickets
        LastUpdatedDateTime
        StoreID
        Store {
          Value {
            Description
            StoreNumber
          }
        }
        OwnedByUser {
          Value {
              Firstname
              Surname
          }
        }
      }
    }
  }
`;

export const StackScreenQuery = gql`
query StackScreen(
    $id: Int!
  ) {
    screen: Stack(
      iD: $id
    ) {
        ID
        Description
        Active
        Message
        PrivateStack
        TotalTickets
        CreatedByUserID
        LastUpdatedByUserID
        LastUpdatedDateTime
        OrganisationID
        OwnedByUserID
        StackTickets {
          ID
          Quantity
          TicketID
          Ticket {
            Value {
              TicketReference
              Size {
                Value {
                  Name
                }
              }
            }
          }
        }
    }
}` 

export const StacksCompareGridQuery = gql`
  query StacksCompareGridQuery(
    $skip: Int,
    $take: Int,
    $order: [StackSortInput!],
    $where: StackFilterInput
  ) {
    grid: Stacks(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        Active
        StoreID
      }
    }
  }
`;