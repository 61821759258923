import { gql } from "@apollo/client";

export type MutatorPresetGroupType = {
  label: string;
  value: number;
  items: Array<{
    ID: number;
    OverlayMarginShapeID: number;
    Mutator1ID: number;
    Mutator2ID: number;
    Mutator3ID: number;
    Mutator4ID: number;
    Mutator5ID: number;
    Mutator6ID: number;
    Mutator7ID: number;
    Mutator8ID: number;
  }>
}

export const MutatorPresetGroupsListQuery = gql`
  query MutatorPresetGroupsListQuery(
    $skip: Int,
    $take: Int,
    $order: [MutatorPresetGroupSortInput!],
    $where: MutatorPresetGroupFilterInput
  ) {
    dropdown: MutatorPresetGroups(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        label: Description
        value: ID
        items: MutatorPresets {
          ID
          OverlayMarginShapeID
          Mutator1ID
          Mutator2ID
          Mutator3ID
          Mutator4ID
          Mutator5ID
          Mutator6ID
          Mutator7ID
          Mutator8ID
        }
      }
    }
  }
`;