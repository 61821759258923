import { gql } from "@apollo/client";
import { Prettify } from "@types";

export type DistributionPresetLazyType = {
  DistributionPresetItems: {
    StoreID: number;
    Quantity: number;
    SizeID: number;
  }[]
}

export type DistributionPresetType = Prettify<{
  ID: number;
  Description: string;
  OrganisationID: number;
} & DistributionPresetLazyType>

export const DistributionPresetsGridQuery = gql`
  query DistributionPresetGrid(
    $skip: Int,
    $take: Int,
    $order: [DistributionPresetSortInput!],
    $where: DistributionPresetFilterInput
  ) {
  grid: DistributionPresets(
    skip: $skip,
    take: $take,
    order: $order,
    where: $where
  ) {
    totalCount
    items {
      ID
      Description
      OrganisationID
      DistributionPresetItems {
        StoreID
        Quantity
        SizeID
      }
    }
  }
}`;