import { gql } from "@apollo/client";
import { Prettify } from "@types";

export type StackQueueLazyType = {
  Store: {
    Value?: {
      Description: string;
    }
  }
}

export type StackQueueType = Prettify<{
  ID: number;
  Description: string;
  OrganisationID: number;
  Completed: boolean;
  Downloaded: boolean;
  Printed: boolean;
  FileKey: string;
  DirectPrint: boolean;
  Quantity: number;
  DateTimeQueued: string;
  StoreID: number;
} & StackQueueLazyType>

export const StackQueuesGridQuery = gql`
  query StackQueueGrid(
    $skip: Int,
    $take: Int,
    $order: [StackQueueSortInput!],
    $where: StackQueueFilterInput
  ) {
  grid: StackQueues(
    skip: $skip,
    take: $take,
    order: $order,
    where: $where
  ) {
    totalCount
    items {
      ID
      Description
      OrganisationID
      Completed
      Downloaded
      Printed
      FileKey
      DirectPrint
      Quantity
      DateTimeQueued
      StoreID
      Store {
        Value {
          Description
        }
      }
    }
  }
}`;