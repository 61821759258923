import { lazy } from "react";
// default Tungsten Pages
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPreset = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const TimeLogs = lazy(() => import('./TimeLogs'))
export const TimeLogHistory = lazy(() => import('./TimeLogHistory'))
export const TimeLogReport = lazy(() => import('./TimeLogReport'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))
export const Wiki = lazy(() => import('./Wiki'))
// customize Pages
export const OverlayGraphics = lazy(() => import('./CustomPages/OverlayGraphics'))
export const OverlayTypes = lazy(() => import('./CustomPages/OverlayTypes'))
export const Customers = lazy(() => import('./CustomPages/Customers'))
export const MyCompany = lazy(() => import('./CustomPages/MyCompany'))
export const Tickets = lazy(() => import('./CustomPages/Tickets'))
export const Stacks = lazy(() => import('./CustomPages/Stacks'))
export const MainMenu = lazy(() => import('./CustomPages/MainMenu'))
export const TicketTypes = lazy(() => import('./CustomPages/TicketTypes'))
export const TicketClasses = lazy(() => import('./CustomPages/TicketClasses'))
export const TicketDepartments = lazy(() => import('./CustomPages/TicketDepartments'))
export const TicketDepartmentGroups = lazy(() => import('./CustomPages/TicketDepartmentGroups'))
export const Stores = lazy(() => import('./CustomPages/Stores'))
export const ConfirmDebit = lazy(() => import('./CustomPages/ConfirmDebit'))
export const Phrases = lazy(() => import('./CustomPages/Phrases'))
export const FieldAndInputControls = lazy(() => import('./CustomPages/FieldAndInputControls'))
export const Sizes = lazy(() => import('./CustomPages/Sizes'))
export const Fonts = lazy(() => import('./CustomPages/Fonts'))
export const StyleControls = lazy(() => import('./CustomPages/StyleControls'))
export const DistributionPreset = lazy(() => import('./CustomPages/DistributionPreset'))
export const Printers = lazy(() => import('./CustomPages/Printers'))
export const PrinterConfigs = lazy(() => import('./CustomPages/PrinterConfigs'))
export const Products = lazy(() => import('./CustomPages/Products'))
export const ProductSuppliers = lazy(() => import('./CustomPages/ProductSuppliers'))
export const ProductBrands = lazy(() => import('./CustomPages/ProductBrands'))
export const ProductPromotions = lazy(() => import('./CustomPages/ProductPromotions'))
export const CopyCustomerDashboard = lazy(() => import('./CustomPages/CopyCustomerDashboard'))