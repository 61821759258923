const isAlphasignByDefault = window.location.hostname.endsWith('alphasign.uk')
export default {
    isAlphasignByDefault,
    logo: {
        auth: isAlphasignByDefault ? '/media/logos/alphasign.png' : '/media/logos/logo.png',
        splash: isAlphasignByDefault ? '/media/logos/alphasign.png' : '/media/logos/logo.png',
        navbar: isAlphasignByDefault ? '/media/logos/alphasign.png' : '/media/logos/logo.png'
    },
    background: '/media/bg/sc-home1-bg.png',
    brandName: isAlphasignByDefault ? 'Alphasign' : 'Ticketwriter',
    brandSite: 'https://www.laserticketing.com',
    brandingFooter: `&copy; Laser Ticketing Systems Ltd - 1988 - ${new Date().getFullYear()}. All rights reserved.`,
    allowOrganisationSelection: true,
    allowImpersonation: true,
    allowFieldHelpText: true,
    timeTracking: false,
    dateFormatDayLightSaving: false,
    landingPath: '/',
    wikiPath: '/tw/user-guide',
    color: {
        primary: '#7E8299',
        secondary: '#3B4F64',
        loginSide: '#7E8299'
    },
    applicationAreaID: isAlphasignByDefault ? 2 : 1,
    metronic: {
        quickPanel: true,
        quickUser: true,
        quickActions: false,
        menu: true,
        aside: false,
        search: true,
        notifications: true,
        subheader: false
    },
    services: {
        cdn: process.env.REACT_APP_CDN_URL!,
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!,
        onesignal: process.env.REACT_APP_ONE_SIGNAL_ID!,
        getaddress: process.env.REACT_APP_GET_ADDRESS_API_KEY,
        gqlBatchRequest: false,
        gqlBatchMax: 5,
        gqlBatchInterval: 20,
        gqlBatchDebounce: false
    },
    token: {
        name: 'brandTK',
        refreshTokenInterval: 30000,
        expireday: 1, // 1 day
        domain: '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    notification: {
        soundEffectEnable: true,
        pathSoundEffect: '/media/audio/mixkit-attention-bell-ding.wav'
    },
    toaster: {
        duration: 3000,
        placement: "top",
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        simultaneousPreventionMessage: "You have been logged out because your User Account has logged in elsewhere.",
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }, 
    menuIcons: {
        default: 'fa fa-layer-group'
    },
    menuPopups: {
    },
    filterGQLError: (count: number, error: any, operation: any) => {
        return true
    }
}