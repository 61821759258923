import { gql } from "@apollo/client";

export type OverlayMarginPresetGroupType = {
  label: string;
  value: number;
  items: Array<{
    ID: number;
    Size: string;
    OverlayMarginShapeID: number;
    TopMargin: number;
    TopMarginMM: number;
    LeftMargin: number;
    LeftMarginMM: number;
    RightMargin: number;
    RightMarginMM: number;
    BottomMargin: number;
    BottomMarginMM: number;
  }>
}

export const OverlayMarginPresetGroupsListQuery = gql`
  query OverlayMarginPresetGroupsListQuery(
    $skip: Int,
    $take: Int,
    $order: [OverlayMarginPresetGroupSortInput!],
    $where: OverlayMarginPresetGroupFilterInput
  ) {
    dropdown: OverlayMarginPresetGroups(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        label: Description
        value: ID
        items: OverlayMarginPresets {
          ID
          Size
          OverlayMarginShapeID
          TopMargin
          TopMarginMM
          LeftMargin
          LeftMarginMM
          RightMargin
          RightMarginMM
          BottomMargin
          BottomMarginMM
        }
      }
    }
  }
`;