import { useQuery } from "@apollo/client"
import branding from "@config/branding"
import { useActiveBrowserTab } from "@hooks/useActiveBrowserTab"
import { useTicketwriterPermission } from "@hooks/useTicketwriterPermission"
import { StackQueueType, StackQueuesGridQuery } from "@services/query/grid/custom/StackQueuesQuery"
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import { useAuthParamAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI"
import { useWorldTrigger } from "@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger"
import { emptyArray } from "@whitecobalt/tungsten/esm/common/utils/assets"
import { timeFormat } from "@whitecobalt/tungsten/esm/common/utils/dateFormat"
import downloadable from "@whitecobalt/tungsten/esm/common/utils/downloadable"
import Grid from "@whitecobalt/tungsten/esm/components/Grid"
import Helptip from "@whitecobalt/tungsten/esm/components/Helptip"
import Icon from "@whitecobalt/tungsten/esm/components/Icon"
import { useSession } from "@whitecobalt/tungsten/esm/components/Session"
import Toaster from "@whitecobalt/tungsten/esm/components/Toaster"
import React, { useEffect, useState } from 'react'
import Badge from "react-bootstrap/Badge"
import worldTrigger from "world-trigger"

export const PANEL_TITLE = "Print Queue"

export const PANEL_ICON = (
    <Icon name="print"/>
)

export const PANEL_TOGGLE_ICON = (
    <Icon className="text-primary" name="print"/>
)

export const PANEL_REFRESH = () => worldTrigger.dispatchTrigger("quick.panel.refresh")

export const PANEL_ACTION = (
    <div className="flex-1 px-2">
        <Helptip id="refresh-panel" description="Refresh Print Queue" overlayTriggerProps={{placement: "bottom"}}>
            <button className="btn btn-xs btn-icon btn-light btn-hover-primary">
                <Icon name="sync" onClick={PANEL_REFRESH}/>
            </button>
        </Helptip>
        <Helptip id="erase-panel" description="Clear Completed Print Queue Items" overlayTriggerProps={{placement: "bottom"}}>
            <button className="btn btn-xs btn-icon btn-light btn-hover-primary ml-2">
                <Icon name="eraser" onClick={() => worldTrigger.dispatchTrigger("quick.panel.clear")}/>
            </button>
        </Helptip>
    </div>
)

const PanelOffcanvas: React.FunctionComponent = () => {
    const requestStatusChange = useAuthParamAPI('/api/Engine/downloaded?StackQueueID={id}', { method: "POST" })
    const active = useActiveBrowserTab()
    const enableQuery = active && requestStatusChange.loading === false
    const [{user}] = useSession()
    const { isAdmin } = useTicketwriterPermission()
    const getStackQueue = useQuery<{ grid: { items: StackQueueType[] }}>(StackQueuesGridQuery, {
        fetchPolicy: "no-cache",
        // skip: true,
        variables: {
            take: 50,
            where: {
                UserID: !isAdmin ? {
                    equals: user?.id
                } : undefined
            },
            order: {
                DateTimeQueued: "DESC"
            }
        },
    })

    const items = getStackQueue.data?.grid.items || emptyArray

    useEffect(() => {
        if(user?.autoDownloadStacks && active) {
            const newCompletedItems = items.filter((item) => item.Completed && !item.DirectPrint && !item.Downloaded && item.FileKey)

            newCompletedItems.forEach((item) => downloadFileKey(item))
        }
    }, [JSON.stringify(items), user])

    useEffect(() => {
        if(!enableQuery) return;

        const handler = () => {
            getStackQueue.refetch()
        }

        // handler()

        const cleanup = setInterval(handler, 30000)

        return () => clearInterval(cleanup)
    }, [enableQuery])

    const [loading, setLoading] = useState<boolean>(false)

    useWorldTrigger('quick.panel.refresh', async () => {
        setLoading(true)
        await getStackQueue.refetch()
        setLoading(false)
    }, [])

    const requestErase = useAuthAPI('/api/Engine/clear')
    useWorldTrigger('quick.panel.clear', async () => {
        try {
            setLoading(true)
            const { data } = await requestErase.call()
            await getStackQueue.refetch()
            setLoading(false)
        } catch {
            setLoading(false)
        }
        
    }, [])
    
    const requestDownload = useAuthAPI('/api/FileURL')
    const downloadFileKey = (item: StackQueueType) => {
        requestDownload.call({
            data: {
                fileKey: item.FileKey,
                thumbnail: item.FileKey.includes('upload'),
                forcedownload: true
            }
        })
        .then((response) => {
            if(response.data.url) {
                requestStatusChange.call({
                    id: item.ID
                }).then(() => {
                    downloadable({
                        url: response.data?.url
                    })
                    getStackQueue.refetch()
                })
            } else {
            }
        })
        .catch((error) => {
            Toaster.error(error?.response?.data?.message || branding.messages.error)
        })
    }

    const handleDownload = (item: StackQueueType): React.MouseEventHandler => (event) => {
        event.preventDefault()

        downloadFileKey(item)
    }

    const requestReprint = useAuthParamAPI('/api/Engine/reprint?StackQueueID={id}', { method: "POST" })
    const handleReprint = (item: StackQueueType): React.MouseEventHandler => async (event) => {
        event.preventDefault()

        if(requestReprint.loading) return;

        const toast = Toaster("Reprinting", { placement: "bottom-right" })
        try {
            const { data } = await requestReprint.call({id: item.ID})
            toast.success(data.message || "Success!")
        } catch {
            toast.error()
        }
    }

    const requestCancel = useAuthParamAPI('/api/Engine/cancel?StackQueueID={id}', { method: "POST" })
    const handleCancel = (item: StackQueueType): React.MouseEventHandler => async (event) => {
        event.preventDefault()

        if(requestCancel.loading) return;

        const toast = Toaster("Canceling", { placement: "bottom-right" })
        try {
            const { data } = await requestCancel.call({id: item.ID})
            toast.success(data.message || "Success!")
            getStackQueue.refetch()
        } catch {
            toast.error()
        }
    }

    return (
        <div className="pt-5">
            <Grid 
                data={items}
                totalCount={items.length}
                totalCountAsPerPage
                showFooter={false}
                facadeLoading={getStackQueue.loading}
                loading={loading}
                columns={[
                    {
                        label: '',
                        dataIndex: 'DateTimeQueued',
                        render: (_: any, item: StackQueueType) => {
                            return (
                                <div>
                                    <div className="d-flex justify-content-between align-items-center gap-2">
                                        <div>
                                            <div>
                                                <small>
                                                    {timeFormat(item.DateTimeQueued, 'en-GB', {
                                                        hour: 'numeric',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    })}
                                                </small>
                                                {item.StoreID && (
                                                    <small> - <i>{item.Store.Value?.Description || ""}</i></small>
                                                )}
                                            </div>
                                            <div className="text-break">{item.Description}</div>
                                        </div>
                                        <div className="d-flex">
                                            <Badge variant={item.Completed ? "success" : "info"}>
                                                {!item.Completed && (
                                                    <Icon name="sync-alt" className="fa-spin mr-2"/>
                                                )}
                                                {item.Completed 
                                                    ? item.DirectPrint ? item.Printed ? "Completed" : "Queued" :  item.Downloaded ? "Completed" : "Ready" 
                                                    : "Processing"}
                                            </Badge>
                                            {item.Completed ? (
                                                <>
                                                    {item.DirectPrint && (
                                                        <Helptip id={`reprint-queue-${item.ID}`} description="Reprint">
                                                            <a href="#" onClick={handleReprint(item)}>
                                                                <Icon name="print" className="clickable p-1 ml-2"/>
                                                            </a>
                                                        </Helptip>
                                                    )}
                                                    {!!item.FileKey ? (
                                                        <Helptip id={`dl-queue-${item.ID}`} description="Download">
                                                            <a href="#" onClick={handleDownload(item)}>
                                                                <Icon name="download" className="clickable p-1 ml-2"/>
                                                            </a>
                                                        </Helptip>
                                                    ) : (
                                                        <span className="mr-8"/>
                                                    )}
                                                </>
                                            ) : (
                                                <Helptip id={`dl-queue-${item.ID}`} description="Cancel">
                                                    <a href="#" onClick={handleCancel(item)}>
                                                        <Icon name="times" className="clickable p-1 ml-2"/>
                                                    </a>
                                                </Helptip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                ]}
                className="table table-head-custom table-head-bg table-vertical-center table-zebra table-hover table-headless"
            />
        </div>
    )
}

export default PanelOffcanvas