import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import CustomPublic from "./pages/CustomPublic";
import {
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Reports,
	Widgets,
	Dashboard,
	Invoices,
	PermissionPreset,
	DocumentTemplates,
	EmailTemplates,
	SMSTemplates,
	Wiki,
	// TimeLogHistory,
	// Bills,
	// PaymentRuns,
	// PaymentAccounts,
	// TimeLogReport,
	MainMenu,
	Tickets,
	Stacks,
	OverlayGraphics,
	OverlayTypes,
	TicketTypes,
	TicketClasses,
	MyCompany,
	Customers,
	ConfirmDebit,
	TicketDepartments,
	TicketDepartmentGroups,
	Stores,
	Phrases,
	FieldAndInputControls,
	Sizes,
	Fonts,
	StyleControls,
	BackgroundProcesses,
	DisconnectedProcesses,
	DistributionPreset,
	Printers,
	PrinterConfigs,
	Products,
	ProductSuppliers,
	ProductBrands,
	CopyCustomerDashboard,
	ProductPromotions
} from './pages'
import CacheGQL from "@components/CacheGQL";
import { useOrganisation } from "@whitecobalt/tungsten/esm/common/hooks/useOrganisation";
import { alphasignLogo } from "@utils/customVariables";
import { useHasMFAPrompt } from "@services/store/global";
import { useTicketwriterPermissionDepartmentGroupUpdate } from "@hooks/useTicketwriterPermissionDepartmentGroupUpdate";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	const [{ loading, user, others }, dispatch] = useSession();
	const organisation = useOrganisation()
	const [hasMFAPrompt] = useHasMFAPrompt()
	const hasAuthenthication = !!(
		user &&
		(branding.allowOrganisationSelection === false ||
		(branding.allowOrganisationSelection &&
			others.hasSelectedOrganisation)) &&
		hasMFAPrompt
	)

	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}

	const buyerPermissionDepartmentGroupUpdate = useTicketwriterPermissionDepartmentGroupUpdate()

	if(loading || buyerPermissionDepartmentGroupUpdate.loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4" data-env-impose="true">
					<img src={organisation.data?.alphasign ? alphasignLogo : branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<Route path="/public">
				<CustomPublic />
			</Route>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo='/auth/login'
				path="/">
				<CacheGQL>
					<Layout>
						<ErrorBoundary showError={branding.services.env !== 'production'}>
							<Suspense fallback={(<Loader active />)}>
								<Switch>
									<MetronicRoute path="/registered-users" component={RegisteredUsers} />
									<MetronicRoute path="/dashboard" component={Dashboard} />
									<MetronicRoute path="/reporting/reports" component={Reports} />
									<MetronicRoute path="/comms/emails" component={SentEmails} />
									<MetronicRoute path="/comms/sms" component={SentSMS} />
									<MetronicRoute path="/comms/document-templates" component={DocumentTemplates} />
									<MetronicRoute path="/comms/email-templates" component={EmailTemplates} />
									<MetronicRoute path="/comms/sms-templates" component={SMSTemplates} />
									<MetronicRoute path="/admin/syssettings" component={SystemSettings} />
									<MetronicRoute path="/admin/widgets" component={Widgets} />
									<MetronicRoute path="/admin/users" component={Users} />
									<MetronicRoute path="/admin/invoices" component={Invoices} />
									{/* <MetronicRoute path="/admin/bills" component={Bills} /> */}
									<MetronicRoute path="/admin/permission-presets" component={PermissionPreset} />
									{/* <MetronicRoute path="/admin/payment-accounts" component={PaymentAccounts} /> */}
									{/* <MetronicRoute path="/admin/payment-runs" component={PaymentRuns} /> */}
									{/* <MetronicRoute path="/time-logs" component={TimeLogHistory} /> */}
									{/* <MetronicRoute path="/time-log-report" component={TimeLogReport} /> */}
									<MetronicRoute path="/admin/bg-processes" component={BackgroundProcesses} />
									<MetronicRoute path="/admin/job-queue" component={DisconnectedProcesses} />
									<MetronicRoute path={branding.wikiPath} component={Wiki} />

									{/** Custom Pages - Start */}
									<MetronicRoute path="/admin/ticket-classes" component={TicketClasses} />
									<MetronicRoute path="/admin/fonts" component={Fonts} />
									<MetronicRoute path="/admin/customers" component={Customers} />
									<MetronicRoute path="/admin/copy-dashboard" component={CopyCustomerDashboard} />
									<MetronicRoute path="/admin/ticket-types" component={TicketTypes} />
									<MetronicRoute path="/confirm-debit" component={ConfirmDebit} />
									<MetronicRoute path="/my-company" component={MyCompany} />
									<MetronicRoute path="/stacks" component={Stacks} />
									<MetronicRoute path="/tickets" component={Tickets} />
									<MetronicRoute path="/config/overlay-graphics" component={OverlayGraphics} />
									<MetronicRoute path="/config/overlay-types" component={OverlayTypes} />
									<MetronicRoute path="/config/departments" component={TicketDepartments} />
									<MetronicRoute path="/config/department-groups" component={TicketDepartmentGroups} />
									<MetronicRoute path="/config/stores" component={Stores} />
									<MetronicRoute path="/config/phrases" component={Phrases} />
									<MetronicRoute path="/config/fi-controls" component={FieldAndInputControls} />
									<MetronicRoute path="/config/sizes" component={Sizes} />
									<MetronicRoute path="/config/styles" component={StyleControls} />
									<MetronicRoute path="/config/preset-dist" component={DistributionPreset} />
									<MetronicRoute path="/config/users" component={Users} />
									<MetronicRoute path="/config/printers" component={Printers} />
									<MetronicRoute path="/config/printer-config" component={PrinterConfigs} />
									<MetronicRoute path="/product/products" component={Products} />
									<MetronicRoute path="/product/product-brands" component={ProductBrands} />
									<MetronicRoute path="/product/product-suppliers" component={ProductSuppliers} />
									<MetronicRoute path="/product/promotions" component={ProductPromotions} />
									<MetronicRoute path="/" exact component={MainMenu} />
									{/** Custom Pages - End */}
									{/* <Redirect path="/" exact to={branding.landingPath} /> */}
									<Route path="*" component={ErrorPage}/>
								</Switch>
							</Suspense>
						</ErrorBoundary>
					</Layout>
				</CacheGQL>
			</ConditionalRoute>
			{/* <Route path="*" component={ErrorPage}/> */}
		</Switch>
	);
}

export default Routes