import { useQuery } from "@apollo/client"
import { TicketDepartmentGroupsGridQuery, TicketDepartmentGroupType } from "@services/query/grid/custom/TicketDepartmentGroupsQuery"
import { excludeDuplicate } from "@whitecobalt/tungsten/esm/common/utils/data"
import { useSession, SessionActionType } from "@whitecobalt/tungsten/esm/components/Session"

export const useTicketwriterPermissionDepartmentGroupUpdate = () => {
    const [{user}, dispatch] = useSession()

    const isBuyer = user?.ticketWriterUserTypeID === 2

    const request = useQuery<{ grid: { items: TicketDepartmentGroupType[] } }>(TicketDepartmentGroupsGridQuery, {
        skip: !isBuyer || (user?.ticketDepartmentGroupIDs || []).length === 0,
        variables: {
            where: {
                ID: {
                    in: user?.ticketDepartmentGroupIDs
                }
            }
        },
        onCompleted: (response) => {
            dispatch({
                type: SessionActionType.SET_USER,
                payload: {
                    ...user,
                    ticketDepartmentIDs: excludeDuplicate([
                        ...(user?.ticketDepartmentIDs || []),
                        ...response.grid.items.map((item) => item.TicketDepartmentIDs).flat()
                    ])
                }
            })
        }
    })

    return {
        loading: request.loading
    }
}