/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { MenuListQuery } from "@services/query/MenuListQuery";
import Facade from "@whitecobalt/tungsten/Facade"
import { useQuery } from "@apollo/client";
import { emptyArray } from "@whitecobalt/tungsten/utils/assets";
import { createDataChildren } from "@whitecobalt/tungsten/utils/data";
import branding from "@config/branding";
import worldTrigger from "world-trigger";
import { useGlobalStoreSelector } from "@services/store/global";
import './index.scss'

export function AsideMenuList({ layoutProps }) {
	const [, setMenuListLoaded] = useGlobalStoreSelector('menuListLoaded')
	const location = useLocation()

	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
		? ` ${!hasSubmenu &&
			"menu-item-active"} menu-item-open menu-item-not-hightlighted`
		: "";
	}

	const requestMenu = useQuery(MenuListQuery, {
		fetchPolicy: "no-cache",
		variables: { MenuID: 1 },
	})

	useEffect(() => {
		setMenuListLoaded(!requestMenu.loading)
	}, [requestMenu.data, requestMenu.loading])

	const data = requestMenu.data?.MenuItems?.items || emptyArray

	const dataWithChildren = useMemo(() => {
		return createDataChildren(data, 'ID', 'ParentMenuItemID')
	}, [data])

	if(requestMenu.loading) {
		return (
			<ul className={`menu-nav ${layoutProps.ulClasses}`} style={{pointerEvents: 'none'}}>
				{new Array(3).fill(0).map((_, index) => (
					<li key={index} className="menu-item menu-item-submenu menu-item-open"
						>
						<a className="menu-link menu-toggle active" href="#">
							{(branding.menuIcons.default) && (
								<span className="svg-icon menu-icon">
									<i className={`mr-2 ${branding.menuIcons.default}`}/>
								</span>
							)}
							<span className="menu-text"><Facade loading rowListStyle={{margin: 0}} range={[40, 70]} rowStyle={{height: 13}} padding={false} rows={1}/></span>
						</a>
						<div className="menu-submenu menu-item-active menu-item-open menu-item-not-hightlighted d-block">
							<i className="menu-arrow" />
							<ul className="menu-subnav">
								<li className="menu-item menu-item-parent" aria-haspopup="true">
									<span className="menu-link">
										<span className="menu-text">
											<Facade loading rowListStyle={{margin: 0}} range={[40, 70]} rowStyle={{height: 13}} padding={false} rows={1}/>
										</span>
									</span>
								</li>
								{new Array(3).fill(0).map((_, index) => (
									<li key={index}
										className={`menu-item`}
										aria-haspopup="true"
									>
										<a className="menu-link" href="#">
											{(branding.menuIcons.default) && (
												<span className="svg-icon menu-icon">
													<i className="menu-dot"/>
												</span>
											)}
											<span className="menu-text">
												<Facade loading rowListStyle={{margin: 0}} range={[40, 70]} rowStyle={{height: 13}} padding={false} rows={1}/>
											</span>
										</a>
									</li>
								))}
							</ul>
						</div>
					</li>
				))}
			</ul>
		)
	}

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
		{dataWithChildren.map((item) => {
			const isMenuPopup = branding.menuPopups[item.Title]
			const LinkComponent = item.LaunchExternal || isMenuPopup ? 'a' : NavLink

			return !item.children ? (
				<li 
					key={item.ID}
					className={`menu-item ${getMenuItemActive(item.URL || "", false)}`}
					aria-haspopup="true"
				>
					<LinkComponent className="menu-link d-flex w-100 h-100" {...({
						[LinkComponent === 'a' ? 'href' : 'to']: item.URL || "",
						target: item.LaunchExternal ? "_blank" : undefined,
						onClick: isMenuPopup ? (e) => {
						e.preventDefault()
						worldTrigger.dispatchTrigger(isMenuPopup)
						}: undefined
					})}>
						<div className="d-flex w-100">
							{((item.Icon && item.Icon !== 'menu-icon') || branding.menuIcons[item.Title] || branding.menuIcons.default) && (
								<span className="svg-icon menu-icon">
									<i className={`mr-2 ${item.Icon || branding.menuIcons[item.Title] || branding.menuIcons.default}`}/>
								</span>
							)}
							<span className="menu-text">{item.Title}</span>
						</div>
					</LinkComponent>
				</li>
			) : (
				<li
					key={item.ID}
					className={`menu-item menu-item-submenu ${getMenuItemActive(
					item.URL || '',
					true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to={item.URL || ''}>
						<div className="d-flex w-100">
							{((item.Icon && item.Icon !== 'menu-icon') || branding.menuIcons[item.Title] || branding.menuIcons.default) && (
								<span className="svg-icon menu-icon">
									<i className={`mr-2 ${item.Icon || branding.menuIcons[item.Title] || branding.menuIcons.default}`}/>
								</span>
							)}
							<span className="menu-text">{item.Title}</span>
						</div>
					</NavLink>
					<div className="menu-submenu ">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">{item.Title}</span>
								</span>
							</li>
							{/* Feedback */}
							{/*begin::2 Level*/}
							{item.children.map((subitem) => {
								const isMenuPopup = branding.menuPopups[subitem.Title]
								const LinkComponent = subitem.LaunchExternal || isMenuPopup ? 'a' : NavLink

								return !subitem.children ? (
									<li
										key={subitem.ID}
										className={`menu-item ${getMenuItemActive(
											subitem.URL || ""
										)}`}
										aria-haspopup="true"
										>
										<LinkComponent className="menu-link" {...({
											[LinkComponent === 'a' ? 'href' : 'to']: subitem.URL || "",
											target: subitem.LaunchExternal ? "_blank" : undefined,
											onClick: isMenuPopup ? (e) => {
											e.preventDefault()
											worldTrigger.dispatchTrigger(isMenuPopup)
											}: undefined
										})}>
											<div className="d-flex w-100">
												{((subitem.Icon && subitem.Icon !== 'menu-icon') || branding.menuIcons[subitem.Title] || branding.menuIcons.default) && (
													<span className="svg-icon menu-icon">
														<i className={`mr-2 ${subitem.Icon || branding.menuIcons[subitem.Title] || 'menu-dot'}`}/>
													</span>
												)}
												<span className="menu-text">{subitem.Title}</span>
											</div>
										</LinkComponent>
									</li>
								) : (
									<li
										key={subitem.ID}
										className={`menu-item menu-item-submenu ${getMenuItemActive(
											subitem.URL,
											true
										)}`}
										aria-haspopup="true"
										data-menu-toggle="hover"
									>
										<NavLink
											className="menu-link menu-toggle"
											to={subitem.URL}
										>
											<div className="d-flex w-100">
												{((subitem.Icon && subitem.Icon !== 'menu-icon') || branding.menuIcons[subitem.Title] || branding.menuIcons.default) && (
													<span className="svg-icon menu-icon">
														<i className={`mr-2 ${subitem.Icon || branding.menuIcons[subitem.Title] || 'menu-dot'}`}/>
													</span>
												)}
												<span className="menu-text">{subitem.Title}</span>
											</div>
											<i className="menu-arrow" />
										</NavLink>
										<div className="menu-submenu">
											<i className="menu-arrow" />
											<ul className="menu-subnav">
											{/*begin::3 Level*/}
											{subitem.children.map((sub2item) => {
												return (
													<li
														key={sub2item.ID}
														className={`menu-item ${getMenuItemActive(
															sub2item.URL || ""
														)}`}
														aria-haspopup="true"
														>
														<LinkComponent className="menu-link" {...({
															[LinkComponent === 'a' ? 'href' : 'to']: sub2item.URL || "",
															target: sub2item.LaunchExternal ? "_blank" : undefined,
															onClick: isMenuPopup ? (e) => {
															e.preventDefault()
															worldTrigger.dispatchTrigger(isMenuPopup)
															}: undefined
														})}>
															<div className="d-flex w-100">
																{((sub2item.Icon && sub2item.Icon !== 'menu-icon') || branding.menuIcons[sub2item.Title] || branding.menuIcons.default) && (
																	<span className="svg-icon menu-icon">
																		<i className={`mr-2 ${sub2item.Icon || branding.menuIcons[sub2item.Title] || 'menu-dot'}`}/>
																	</span>
																)}
																<span className="menu-text">{sub2item.Title}</span>
															</div>
														</LinkComponent>
													</li>
												)
											})}
											{/*end::3 Level*/}
											</ul>
										</div>
									</li>
								)
							})}
							
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>
			)
		})}
    </ul>
  )
}
