import React, { useEffect, useRef } from 'react'
import Tab from 'react-bootstrap/Tab'
import Button from '@whitecobalt/tungsten/Button'
import Toaster from '@whitecobalt/tungsten/Toaster'
import FormManager from '@whitecobalt/tungsten/FormManager'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import branding from '@config/branding'
import SelectDropdown, { SelectDropdownGraphReference } from "@whitecobalt/tungsten/esm/components/Fields/SelectDropdown"
import { PrintersDropdownQuery, StacksDropdownQuery, StoresDropdownQuery } from "@services/query/DropdownQueries"
import { useOrganisation } from "@whitecobalt/tungsten/esm/common/hooks/useOrganisation"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session"
import { FormEvent, useForm } from "formydable"
import ModalConfirm from "@whitecobalt/tungsten/esm/components/Modal/Confirm"
import { useUserPreventClose } from "../../useUserPreventClose"
import { useWorldTrigger } from "@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger"
import { useTicketwriterPermission } from "@hooks/useTicketwriterPermission"

const UserOffcanvasSettings: React.FunctionComponent = () => {
    const [{ user }, dispatch] = useSession()
    const { data: organisation } = useOrganisation()
    const request = useAuthAPI('/api/UserSettings')
    const form = useForm()
    useUserPreventClose(form.dirty)

    const handleSubmit = (event: FormEvent) => {
        const FORM_DATA = event.json()

        const payload = {
            "storeID": FORM_DATA.storeID || null,
            "currentStackID": FORM_DATA.currentStackID || null,
            "printerID": FORM_DATA.printerID || null,
            "largeFormatPrinterID": FORM_DATA.largeFormatPrinterID || null,
            "autoDownloadStacks": FORM_DATA.autoDownloadStacks,
            "enableAutoStacking": FORM_DATA.enableAutoStacking,
            "useImperialUnits": FORM_DATA.useImperialUnits,
        }

        const toast = Toaster("Updating Settings")
        request.call({ data: payload }).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message || "Success!")
                dispatch({
                    type: SessionActionType.SET_USER,
                    payload: { ...user, ...payload }
                })
                event.setDirty(false)
            } else {
                FormManager.setBackendValidation(event, response.data.errors)
                toast.fail(response?.data?.message || branding.messages.fail)
            }
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message || branding.messages.error
            toast.error(errorMessage);
        })

    }

    const stackRef = useRef<SelectDropdownGraphReference>(null)
    useWorldTrigger('user.setting.stack', () => {
        stackRef.current?.refetch()
    }, [])

    const storeRef = useRef<SelectDropdownGraphReference>(null)
    useWorldTrigger('user.setting.store', () => {
        storeRef.current?.refetch()
    }, [])

    const { isStoreUser, assignedAllStores, assignedStoreIDs } = useTicketwriterPermission()

    return (
        <Tab.Pane eventKey="settings">
            <FormManager.Core fieldtextIdent="user-my-settings" value={form} onSubmit={handleSubmit}>
                <FormManager.Input
                    ref={stackRef}
                    as={SelectDropdown.Graph}
                    gql={StacksDropdownQuery}
                    variables={{
                        where: {
                            Active: {
                                equals: true
                            },
                            OrganisationID: {
                                equals: organisation?.id
                            },
                            OwnedByUserID: isStoreUser ? {
                                equals: user?.id
                            } : undefined,
                        }
                    }}
                    name="currentStackID"
                    label="Current Stack"
                    value={user?.currentStackID}
                />
                {organisation?.enableDistribution && (
                    <FormManager.Input
                        ref={storeRef}
                        as={SelectDropdown.Graph}
                        gql={StoresDropdownQuery}
                        variables={{
                            where: {
                                OrganisationID: {
                                    equals: organisation.id
                                },
                                ID: isStoreUser && !assignedAllStores ? {
                                    in: assignedStoreIDs
                                } : undefined
                            }
                        }}
                        manageOptions={(item: any) => ({
                            value: item.value,
                            label: `${item.StoreNumber} - ${item.label}`
                        })}
                        name="storeID"
                        label="Store"
                        value={user?.storeID}
                    />
                )}
                {organisation?.directPrintEnabled ? (
                    <>
                        <FormManager.States>
                            {(states) => (
                                <React.Fragment key={states.storeID}>
                                    <FormManager.Input
                                        as={SelectDropdown.Graph}
                                        gql={PrintersDropdownQuery}
                                        fetchPolicy="cache-first"
                                        variables={organisation.storesHaveLocalPrinters && states.storeID ? {
                                            where: {
                                                StoreID: {
                                                    equals: states.storeID
                                                }
                                            }
                                        } : undefined}
                                        manageOptions={(item: any) => ({
                                            label: item.label || item.fallback,
                                            value: item.value
                                        })}
                                        name="printerID"
                                        label="Printer"
                                        value={user?.printerID}
                                    />
                                    <FormManager.Input
                                        as={SelectDropdown.Graph}
                                        gql={PrintersDropdownQuery}
                                        fetchPolicy="cache-first"
                                        variables={organisation.storesHaveLocalPrinters && states.storeID ? {
                                            where: {
                                                StoreID: {
                                                    equals: states.storeID
                                                }
                                            }
                                        } : undefined}
                                        manageOptions={(item: any) => ({
                                            label: item.label || item.fallback,
                                            value: item.value
                                        })}
                                        name="largeFormatPrinterID"
                                        label="Large Format Printer"
                                        value={user?.largeFormatPrinterID}
                                    />
                                </React.Fragment>
                            )}
                        </FormManager.States>
                    </>
                ) : (
                    <FormManager.Boolean
                        type="switch"
                        name="autoDownloadStacks"
                        label="Auto-download Stacks"
                        checked={user?.autoDownloadStacks}
                    />
                )}
                <FormManager.Boolean
                    type="switch"
                    name="enableAutoStacking"
                    label="Enable Auto Stacking"
                    checked={user?.enableAutoStacking}
                />
                <FormManager.Boolean
                    type="switch"
                    name="useImperialUnits"
                    label="Use Imperial Units"
                    checked={user?.useImperialUnits}
                />
                <div className="d-flex justify-content-end">
                    <Button type="submit" loading={request.loading} variant="primary">
                        Update
                    </Button>
                </div>
            </FormManager.Core>
        </Tab.Pane>
    )
}

export default UserOffcanvasSettings