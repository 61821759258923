import { gql } from "@apollo/client";
import { Prettify } from "@types";

export type TicketDepartmentGroupLazyType = {
  TicketDepartments: {
    Description: string;
  }[]
}

export type TicketDepartmentGroupType = Prettify<{
  ID: number;
  Description: string;
  OrganisationID: number;
  IsDefault: boolean;
  TicketDepartmentIDs: number[];
} & TicketDepartmentGroupLazyType>

export const TicketDepartmentGroupsGridQuery = gql`
  query TicketDepartmentGroupGrid(
    $skip: Int,
    $take: Int,
    $order: [TicketDepartmentGroupSortInput!],
    $where: TicketDepartmentGroupFilterInput
  ) {
  grid: TicketDepartmentGroups(
    skip: $skip,
    take: $take,
    order: $order,
    where: $where
  ) {
    totalCount
    items {
      ID
      Description
      OrganisationID
      TicketDepartmentIDs
      IsDefault
      TicketDepartments {
        Description
      }
    }
  }
}`;