import branding from "@config/branding"
import { useOrganisation } from "@whitecobalt/tungsten/esm/common/hooks/useOrganisation"
import { useEffect } from "react"


export const useTheme = () => {
    // const [{token}] = useSession()
    const { data } = useOrganisation()

    useEffect(() => {

        const favico = document.querySelector<HTMLLinkElement>('link[type="image/x-icon"]')!

        if(!data) {
            if(branding.isAlphasignByDefault) {
                branding.brandName = "Alphasign"
                document.body.classList.add("theme-alphasign")
                document.title = branding.brandName
                favico.href = "/favicon_io/alpha.ico"
                branding.wikiPath = '/alpha/user-guide'
                branding.applicationAreaID = 2
            } else {
                branding.brandName = "Ticketwriter"
                document.body.classList.remove("theme-alphasign")
                document.title = branding.brandName
                favico.href = "/favicon_io/favicon.ico"
                branding.wikiPath = '/tw/user-guide'
                branding.applicationAreaID = 1
            }

            return;
        } 

        if(data?.alphasign) {
            branding.brandName = "Alphasign"
            document.body.classList.add("theme-alphasign")
            document.title = branding.brandName
            favico.href = "/favicon_io/alpha.ico"
            branding.wikiPath = '/alpha/user-guide'
            branding.logo.auth = '/media/logos/alphasign.png'
            branding.logo.splash = '/media/logos/alphasign.png'
            branding.logo.navbar = '/media/logos/alphasign.png'
            branding.applicationAreaID = 2
        } else {
            branding.brandName = "Ticketwriter"
            document.body.classList.remove("theme-alphasign")
            document.title = branding.brandName
            favico.href = "/favicon_io/favicon.ico"
            branding.wikiPath = '/tw/user-guide'
            branding.logo.auth = '/media/logos/logo.png'
            branding.logo.splash = '/media/logos/logo.png'
            branding.logo.navbar = '/media/logos/logo.png'
            branding.applicationAreaID = 1
        }
        
        // if(branding.isAlphasignByDefault) {
        //     document.title = 'Alphasign'
        //     favico.href = "/favicon_io/alpha.ico"
        //     document.body.classList.add("theme-alphasign")
        // }

        // const pathname = `/auth/redirect/${token}`
        // if(data?.alphasign && !branding.isAlphasignByDefault) {
        //     cookie.unset(branding.token.name)
        //     if(branding.services.env === 'production') return window.location.assign(`https://alphasign.uk${pathname}`)
        //     window.location.assign(`http://uat.alphasign.uk${pathname}`)
        // } else if(!data?.alphasign && branding.isAlphasignByDefault) {
        //     cookie.unset(branding.token.name)
        //     if(branding.services.env === 'production') return window.location.assign(`https://ticketwriter.com${pathname}`)
        //     window.location.assign(`http://uat.ticketwriter.com${pathname}`)
        // }
    }, [data])
}