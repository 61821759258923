import { ProductType } from "@services/query/grid/custom/ProductsQuery"
import { TicketType } from "@services/query/grid/custom/TicketsQuery"
import { useSession } from "@whitecobalt/tungsten/esm/components/Session"

export const useTicketwriterPermission = () => {
    const [{user}] = useSession()

    const permissionEditProduct = (row: ProductType) => user?.adminUser === true 
        || user?.ticketWriterUserTypeID === 1 
        || (user?.ticketWriterUserTypeID === 2 && (user?.allTicketDepartments === true || user?.ticketDepartmentIDs.includes(row.DepartmentID)))

    const permissionEditTicket = (row: TicketType) => user?.adminUser === true 
        || user?.ticketWriterUserTypeID === 1 
        || (user?.ticketWriterUserTypeID === 2 && (user?.allTicketDepartments === true || user?.ticketDepartmentIDs.includes(row.TicketDepartmentID)))

    return {
        isAdmin: user?.adminUser === true || user?.ticketWriterUserTypeID === 1,
        isBuyer: user?.ticketWriterUserTypeID === 2,
        isStoreUser: user?.ticketWriterUserTypeID === 3,
        assignedAllTicketDepartments: user?.allTicketDepartments === true,
        assignedTicketDepartmentIDs: user?.ticketDepartmentIDs,
        assignedAllStores: user?.allStores === true,
        assignedStoreIDs: user?.storeIDs,
        permissionEditProduct,
        permissionEditTicket
    }
}