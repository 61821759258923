import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import ConditionalRoute from '@whitecobalt/tungsten/ConditionalRoute';
import ErrorBoundary from '@whitecobalt/tungsten/ErrorBoundary';
import Loader from '@whitecobalt/tungsten/Loader'
import { useSession } from '@whitecobalt/tungsten/Session';
import branding from '@config/branding'
import { ErrorPage } from '../ErrorPage';
import AuthContainer from './AuthContainer';
import { ContentRoute } from '@metronic/layout';
import { useHasMFAPrompt } from "@services/store/global";
const  OrganistionSelection = lazy(() => import('./OrganistionSelection'))
const  Impersonation = lazy(() => import('./Impersonation'))
const  ConfirmEmail = lazy(() => import('./ConfirmEmail'))
const  Login = lazy(() => import('./Login'))
const  MFA = lazy(() => import('./MFA'))
const  Logout = lazy(() => import('./Logout'))
const  ForgotPassword = lazy(() => import('./ForgotPassword'))
const  PasswordReset = lazy(() => import('./PasswordReset'))
const  RedirectDomain = lazy(() => import('./RedirectDomain'))
const  MFAPrompt = lazy(() => import('./MFAPrompt'))

interface AuthRoutesProps {
    
}

const AuthRoutes: React.FunctionComponent<AuthRoutesProps> = () => {
    const [{ token, redirect, others }] = useSession();
    const [hasMFAPrompt] = useHasMFAPrompt()
    const hasAuthenthication = !!token
    
    const finalRedirection =
      hasMFAPrompt === false
        ? "/auth/mfa-prompt"
        : branding.allowOrganisationSelection && !others.hasSelectedOrganisation
        ? "/auth/select-organisation"
        : redirect || branding.landingPath

    return (
        <Suspense fallback={(<Loader active />)}>
            <ErrorBoundary>
                <AuthContainer>
                    <Switch>
                        <Redirect exact path="/auth" to="/auth/login"/>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={hasAuthenthication}
                            redirectTo='/auth/login'
                            path="/auth/logout"
                            exact>
                            <Logout />
                        </ConditionalRoute>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={!hasAuthenthication}
                            redirectTo={finalRedirection}
                            path="/auth/login"
                            exact>
                            <Login />
                        </ConditionalRoute>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={!hasAuthenthication}
                            redirectTo={finalRedirection}
                            path="/auth/mfa"
                            exact>
                            <MFA />
                        </ConditionalRoute>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={!hasAuthenthication}
                            redirectTo={finalRedirection}
                            path="/auth/forgot-password"
                            exact>
                            <ForgotPassword />
                        </ConditionalRoute>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={true}
                            redirectTo={finalRedirection}
                            path="/auth/password-reset"
                            exact>
                            <PasswordReset />
                        </ConditionalRoute>
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={true}
                            redirectTo={finalRedirection}
                            path="/auth/welcome"
                            exact>
                            <PasswordReset />
                        </ConditionalRoute>
                        {branding.allowOrganisationSelection && (
                            <ConditionalRoute
                                as={ContentRoute}
                                condition={hasAuthenthication && !others.hasSelectedOrganisation}
                                redirectTo={redirect || branding.landingPath}
                                path="/auth/select-organisation"
                                exact>
                                <OrganistionSelection />
                            </ConditionalRoute>
                        )}
                        <ConditionalRoute
                            as={ContentRoute}
                            condition={hasAuthenthication && !hasMFAPrompt}
                            redirectTo={redirect || branding.landingPath}
                            path="/auth/mfa-prompt"
                            exact>
                            <MFAPrompt />
                        </ConditionalRoute>
                        {branding.allowImpersonation && (
                            <Route 
                                path="/auth/impersonation/:originalToken/:impersonationToken/:originalOrgID/:url" 
                                component={Impersonation}
                            />
                        )}
                        <Route 
                            path="/auth/redirect/:token" 
                            component={RedirectDomain}
                        />
                        <Route
                            path="/auth/confirm-email"
                            exact
                            component={ConfirmEmail}/>
                        <Route path="*" component={ErrorPage}/>
                    </Switch>
                </AuthContainer>
            </ErrorBoundary>
        </Suspense>
    )
}

export default AuthRoutes
