import { gql } from "@apollo/client";

export type DropdownCommonType<P = Record<string, any>> = {
  value: number;
  label: string;
} & P

export type DropdownQueryType<P = DropdownCommonType> = {
  dropdown: {
    items: P[];
  };
}


export const AvailableTagsDropdownQuery = gql`
  query AvailableTagsDropdownQuery($where: AvailableTagFilterInput) {
    dropdown: AvailableTags(where: $where) {
      items {
        value: Tag
        label: Tag
        TagColour
        ReversedText
      }
    }
  }
`;


export const FontAwesomeIconsDropdownQuery = gql`
query FontAwesomeIconsDropdownQuery($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query ColourPalettesDropdownQuery($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query OrganisationsDropdownQuery($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query OrganisationsByTypeDropdownQuery(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query SentryProjectDropdownQuery($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query OrganisationTypesDropdownQuery{
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query ParameterTypesDropdownQuery{
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query PermissionsDropdownQuery($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query JobRolesDropdownQuery{
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query UserTypesDropdownQuery{
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query CountriesDropdownQuery{
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query MobileCountriesDropdownQuery($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query TitlesDropdownQuery{
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query EmailTemplatesDropdownQuery($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;


export const ApplicationAreasDropdownQuery = gql`
  query ApplicationAreasDropdownQuery{
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query UsersDropdownQuery($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query CompanyUsersDropdownQuery($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query UsersByUserTypesDropdownQuery($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query AccountSystemContactsDropdownQuery($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query InvoicingModesDropdownQuery($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query BudgetAccountCodeTypesDropdownQuery($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query BudgetAccountCodesDropdownQuery($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query BudgetVersionDropdownQuery{  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query UsersInOrganisationImpersonationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query UsersInOrganisationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query ProjectsDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query TasksDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

/* CUSTOMS - START */

export const TicketFieldTypesDropdownQuery = gql`
  query TicketFieldTypesDropdownQuery($where: TicketFieldTypeFilterInput) {  
    dropdown: TicketFieldTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TicketTabTypesDropdownQuery = gql`
  query TicketTabTypesDropdownQuery($where: TicketTabTypeFilterInput, $order: [TicketTabTypeSortInput!],) {  
    dropdown: TicketTabTypes(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TicketClassesDropdownQuery = gql`
  query TicketClassesDropdownQuery($where: TicketClassFilterInput) {  
    dropdown: TicketClasses(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TicketDepartmentsDropdownQuery = gql`
  query TicketDepartmentsDropdownQuery($where: TicketDepartmentFilterInput) {  
    dropdown: TicketDepartments(where: $where) {
      items {
        value: ID
        label: Description
        Code
        UseDefaultMargin
        MinimumMargin
        TargetMargin
      }
    }
  }
`;
export const TicketDepartmentGroupsDropdownQuery = gql`
  query TicketDepartmentGroupsDropdownQuery($where: TicketDepartmentGroupFilterInput) {  
    dropdown: TicketDepartmentGroups(where: $where) {
      items {
        value: ID
        label: Description
        IsDefault
      }
    }
  }
`;

export const PhraseTypesDropdownQuery = gql`
  query PhraseTypesDropdownQuery($where: PhraseTypeFilterInput) {  
    dropdown: PhraseTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const StylesDropdownQuery = gql`
  query StylesDropdownQuery($where: StyleFilterInput) {  
    dropdown: Styles(where: $where) {
      items {
        value: ID
        label: Description
        Code
        StylePreviews {
          TicketTypeID
          FileKey
        }
      }
    }
  }
`;

export const StylesWithLockOverlayDropdownQuery = gql`
  query StylesDropdownQuery($order: [StyleSortInput!], $where: StyleFilterInput) {  
    dropdown: Styles(order: $order, where: $where) {
      items {
        value: ID
        label: Description
        Code
        LockOverlaySettings
      }
    }
  }
`;

export const BrandLogosDropdownQuery = gql`
  query BrandLogosDropdownQuery($order: [BrandLogoSortInput!], $where: BrandLogoFilterInput) {  
    dropdown: BrandLogos(order: $order, where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const SizesDropdownQuery = gql`
  query SizesDropdownQuery($where: SizeFilterInput) {  
    dropdown: Sizes(where: $where) {
      items {
        value: ID
        label: Name
        Description
        Across
        CutMarks
        Down
        GutterX
        GutterY
        H2H
        Height
        Width
        OffsetX
        OffsetY
        OrganisationID
        Portrait
        UseLargeFormatPrinter
        Width
        GutterXMM
        GutterYMM
        HeightMM
        WidthMM
        OffsetXMM
        OffsetYMM
        Rotate
        RotateColumn
        PaperSize {
          Value {
            Width
            Height
          }
        }
      }
    }
  }
`;

export const TicketTypesDropdownQuery = gql`
  query TicketTypesDropdownQuery($where: TicketTypeFilterInput, $organisationID: Int) {  
    dropdown: TicketTypes(where: $where, OrganisationID: $organisationID) {
      items {
        value: ID
        label: Description
        MultiProductEligible
        SingleProductEligible
        StylePreviews {
          FileKey
          StyleID
        }
      }
    }
  }
`;

export const TicketTypesWithPriceSavingDropdownQuery = gql`
  query TicketTypesDropdownQuery($where: TicketTypeFilterInput, $organisationID: Int) {  
    dropdown: TicketTypes(where: $where, OrganisationID: $organisationID) {
      items {
        value: ID
        label: Description
        TicketClass {
          Value {
            PriceSavingEligible
          }
        }
      }
    }
  }
`;

export const TicketsDropdownQuery = gql`
  query TicketsDropdownQuery($organisationID: Int!, $where: TicketFilterInput) {  
    dropdown: Tickets(OrganisationID: $organisationID, where: $where) {
      items {
        value: ID
        label: TicketReference
      }
    }
  }
`;

export const StacksDropdownQuery = gql`
  query StacksDropdownQuery($where: StackFilterInput) {  
    dropdown: Stacks(where: $where) {
      items {
        value: ID
        label: Description
        StoreID
      }
    }
  }
`;

export const PaperSizesDropdownQuery = gql`
  query PaperSizesDropdownQuery($where: PaperSizeFilterInput) {  
    dropdown: PaperSizes(where: $where) {
      items {
        value: ID
        label: Description
        Height
        Width
        SizeDescription
      }
    }
  }
`;

export const PaperTraysDropdownQuery = gql`
  query PaperTraysDropdownQuery($where: PaperTrayFilterInput) {  
    dropdown: PaperTrays(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PrinterPermissionTypesDropdownQuery = gql`
  query PrinterPermissionTypesDropdownQuery($where: PrinterPermissionTypeFilterInput) {  
    dropdown: PrinterPermissionTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OverlayGraphicShapesDropdownQuery = gql`
  query OverlayGraphicShapesDropdownQuery($where: OverlayGraphicShapeFilterInput) {  
    dropdown: OverlayGraphicShapes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const SurroundTypesDropdownQuery = gql`
  query SurroundTypesDropdownQuery($where: SurroundTypeFilterInput) {  
    dropdown: SurroundTypes(where: $where) {
      items {
        value: Code
        label: Description
      }
    }
  }
`;

export const ColourGroupsDropdownQuery = gql`
  query ColourGroupsDropdownQuery($where: ColourGroupFilterInput, $order: [ColourGroupSortInput!]) {  
    dropdown: ColourGroups(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ColourNumbersDropdownQuery = gql`
  query ColourNumbersDropdownQuery($where: ColourNumberFilterInput, $order: [ColourNumberSortInput!]) {  
    dropdown: ColourNumbers(where: $where, order: $order) {
      items {
        value: ID
        label: Description
        ColourGroupID
      }
    }
  }
`;

export const FontsDropdownQuery = gql`
  query FontsDropdownQuery($where: FontFilterInput) {  
    dropdown: Fonts(where: $where) {
      items {
        value: ID
        label: Description
        TTFFile
        IsBold
        IsItalic
        Symbol
      }
    }
  }
`;

export const FontGroupsDropdownQuery = gql`
  query FontGroupsDropdownQuery($where: FontGroupFilterInput, $order: [FontGroupSortInput!]) {  
    dropdown: FontGroups(where: $where, order: $order) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const FontNamesDropdownQuery = gql`
  query FontNamesDropdownQuery($where: FontNameFilterInput) {  
    dropdown: FontNames(where: $where) {
      items {
        value: ID
        label: Description
        FontGroupID
      }
    }
  }
`;

export const BorderTypesDropdownQuery = gql`
  query BorderTypesDropdownQuery($where: BorderTypeFilterInput) {  
    dropdown: BorderTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OverlayGraphicsDropdownQuery = gql`
  query OverlayGraphicsDropdownQuery($order: [OverlayGraphicSortInput!], $where: OverlayGraphicFilterInput) {  
    dropdown: OverlayGraphics(order: $order, where: $where) {
      items {
        value: ID
        label: Description
        OverlayGraphicLogos {
          ImageManagerResourceLocator
          OverlayGraphicShapeID
        }
        Code
      }
    }
  }
`;

export const TicketMenuTypesDropdownQuery = gql`
  query TicketMenuTypesDropdownQuery($where: TicketMenuTypeFilterInput) {  
    dropdown: TicketMenuTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TicketGridMenuTypesDropdownQuery = gql`
  query TicketGridMenuTypesDropdownQuery($where: TicketGridMenuTypeFilterInput) {  
    dropdown: TicketGridMenuTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OverlayMarginShapesDropdownQuery = gql`
  query OverlayMarginShapesDropdownQuery($where: OverlayMarginShapeFilterInput) {  
    dropdown: OverlayMarginShapes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const StoresDropdownQuery = gql`
  query StoresDropdownQuery($where: StoreFilterInput) {  
    dropdown: Stores(where: $where) {
      items {
        value: ID
        label: Description
        StoreNumber
      }
    }
  }
`;

export const ProductPromotionsDropdownQuery = gql`
  query ProductPromotionsDropdownQuery($where: PromotionFilterInput) {  
    dropdown: Promotions(where: $where) {
      items {
        value: ID
        label: Name
        StartDate
        EndDate
        DefaultStyleID
      }
    }
  }
`;


export const MutatorsDropdownQuery = gql`
  query MutatorsDropdownQuery($where: MutatorFilterInput) {  
    dropdown: Mutators(where: $where) {
      items {
        value: ID
        label: Title
        Graphic
        Description
        IsFeatureGraphic
      }
    }
  }
`;

export const PantoneColoursDropdownQuery = gql`
  query PantoneColoursDropdownQuery($where: PantoneColourFilterInput) {  
    dropdown: PantoneColours(where: $where) {
      items {
        value: ID
        label: PantoneName
        c: C
        m: M
        y: Y
        k: K
      }
    }
  }
`;

export const PhrasesDropdownQuery = gql`
  query PhrasesDropdownQuery($where: PhraseFilterInput) {  
    dropdown: Phrases(where: $where) {
      items {
        value: Content
        label: Content
        PhraseTypeID
      }
    }
  }
`;

export const TemplateSetsDropdownQuery = gql`
  query TemplateSetsDropdownQuery($where: TemplateSetFilterInput) {  
    dropdown: TemplateSets(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PrintersDropdownQuery = gql`
  query PrintersDropdownQuery($where: PrinterFilterInput) {  
    dropdown: Printers(where: $where) {
      items {
        value: ID
        label: Description
        fallback: PrinterMachineDescription
      }
    }
  }
`;

export const PrinterConfigsDropdownQuery = gql`
  query PrinterConfigsDropdownQuery($where: PrinterConfigFilterInput) {  
    dropdown: PrinterConfigs(where: $where) {
      items {
        value: ID
        label: Description
        Default
      }
    }
  }
`;

export const ProductsDropdownQuery = gql`
  query ProductsDropdownQuery($take: Int, $where: ProductFilterInput, $productMessage: String) {  
    dropdown: Products(take: $take, where: $where, ProductMessage: $productMessage) {
      items {
        value: ID
        label: ProductCode
        ProductName
        DepartmentID
        MainTicketInfo1
        MainTicketInfo2
        MainTicketInfo3
        PromotionProducts {
          PromotionID
          SalePrice
          CurrencyID
          Promotion {
            Value {
              Active
              Name
            }
          }
        }
      }
    }
  }
`;

export const ProductBrandsDropdownQuery = gql`
  query ProductBrandsDropdownQuery($where: ProductBrandFilterInput) {  
    dropdown: ProductBrands(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ProductSuppliersDropdownQuery = gql`
  query ProductSuppliersDropdownQuery($where: ProductSupplierFilterInput) {  
    dropdown: ProductSuppliers(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ProductPriceTypesDropdownQuery = gql`
  query ProductPriceTypesDropdownQuery($where: ProductPriceTypeFilterInput) {  
    dropdown: ProductPriceTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TicketWriterUserTypesDropdownQuery = gql`
  query TicketWriterUserTypesDropdownQuery($where: TicketWriterUserTypeFilterInput) {  
    dropdown: TicketWriterUserTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MaterialSymbolsDropdownQuery = gql`
  query MaterialSymbolsDropdownQuery($organisationID: Int) {  
    dropdown: MaterialSymbols(OrganisationID: $organisationID) {
      ImageManagerID
      FileKey
      ImageFileName
      ThumbFileKey
      PathName
    }
  }
`;
/* CUSTOMS - END */