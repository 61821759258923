import branding from '@config/branding';
import Button from '@whitecobalt/tungsten/esm/components/Button'
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import ModalForm from '@whitecobalt/tungsten/esm/components/Modal/Form';
import { SessionActionType, useSession } from '@whitecobalt/tungsten/esm/components/Session';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import React, { useState } from 'react'
import SelectDropdown from '@whitecobalt/tungsten/esm/components/Fields/SelectDropdown';
import { StacksDropdownQuery } from '@services/query/DropdownQueries';
import { useAuthParamAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI";
import Placeholder from "@whitecobalt/tungsten/esm/components/Placeholder";
import { useQuery } from "@apollo/client";
import { StackScreenQuery, StackType } from "@services/query/grid/custom/StacksQuery";
import Helptip from "@whitecobalt/tungsten/esm/components/Helptip";
import Icon from "@whitecobalt/tungsten/esm/components/Icon";
import classNames from "classnames";
import { useStackingStore } from "@services/store/stacking";
import { useTicketwriterPermission } from "@hooks/useTicketwriterPermission";

interface CurrentStackChangeProps {
}

const CurrentStackChange: React.FunctionComponent<CurrentStackChangeProps> = () => {
    const [{user, others}, dispatch] = useSession()
    const defaultStackID = user?.currentStackID || 0
    const { isStoreUser } = useTicketwriterPermission()

    const requestChange = useAuthParamAPI('/api/Stack/set-stack?StackID={id}', { method: 'POST' })
    const handler = (e: any) => {
        const recursivePromptForm = () => {
            ModalForm({
                key: "select-stack",
                title: `Select Stack`,
                titleIcon: `fa fa-edit fa-lg`,
                submit: 'Select',
                allowFieldHelpText: false,
                body: (
                    <FormManager.Input
                        as={SelectDropdown.Graph}
                        gql={StacksDropdownQuery}
                        variables={{
							where: {
								Active: {
									equals: true
								},
								OrganisationID: {
									equals: others.organisationID
								},
								OwnedByUserID: isStoreUser ? {
									equals: user?.id
								} : undefined,
							}
						}}
                        name="stackID"
                        label="Select Stack"
                        value={defaultStackID}
                    />
                ),
            }).then((event) => {
                
                if (event.isSubmit) {
                    if(!event.isReady()) {
                        recursivePromptForm();
                        event.removeLoading();
                        return;
                    }

                    const FORM_DATA = event.json();
                    
                    const toast = Toaster("Processing");

                    requestChange
                        .call({ id: FORM_DATA.stackID || null })
                        .then((response) => {
                            if (response.data.success) {
                                event.closeModal();

                                toast.success(response.data.message || "Success!");

                                dispatch({
                                    type: SessionActionType.SET_USER,
                                    payload: {
                                        ...user,
                                        currentStackID: FORM_DATA.stackID
                                    }
                                })

                            } else {
                                toast.fail(response.data.message || branding.messages.fail);
                                FormManager.setBackendValidation(event, response.data.errors);
                                event.setValidationMessages(response.data.errors?.map((error: any) => error.errorMessage))
                                recursivePromptForm();
                            }
                        })
                        .catch((error) => {
                            recursivePromptForm();
                            event.setError(error?.response?.data?.message || branding.messages.error);
                            toast.error();
                        })
                        .finally(() => {
                            event.removeLoading();
                        });
                    }
                });
        };

        recursivePromptForm();
    }

    const requestScreen = useQuery<{ screen: StackType}>(StackScreenQuery, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        variables: {
            id: defaultStackID
        },
        skip: !defaultStackID
    })

    const [animateAutoStack, setAnimateAutoStack] = useStackingStore()

    const autoStackCount = (
        <div className={classNames("auto-stack-count", { active: animateAutoStack > 0 })} onAnimationEnd={() => setAnimateAutoStack(() => 0)}>
            {animateAutoStack}
        </div>
    )

    return (
        <div className="d-flex align-items-center">
            <Helptip id="current-stack" description="Current Stack" overlayTriggerProps={{ placement: "bottom" }}>
                <Button type="button" variant="primary" size="sm" className="p-2 d-flex justify-content-center position-relative" onClick={handler} style={{minWidth: 100}}>
                    <Placeholder loading={requestScreen.loading} noMargin>
                        {user?.enableAutoStacking && <Icon name="magic" className="mr-1"/>}<b>{requestScreen.data?.screen?.Description || 'Select Stack'}</b>
                    </Placeholder>
                    {autoStackCount}
                </Button>
            </Helptip>
        </div>
    )
}

export default CurrentStackChange
